import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/layout/Layout';

import SplitSection from '../components/SplitSection';
import NewsletterSub from '../components/NewsletterSub';

const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/export/es/acerca-de/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/about-us/`,

  },


];


const seo={
  title: "¿Quiénes Somos?",
  desc:  "Unilimpio S.A. es una empresa orgullosamente ecuatoriana con más de 35 años ofreciendo soluciones de higiene, con productos de limpieza líderes del mercado. ",
  image: "grupo-unilimpio.jpg",
  pathname: "/quienes-somos/",
  alternateEs: "/export/es/acerca-de/",
  alternateEn: "/export/en/about-us/",
  alternateEsec: "/quienes-somos/",
  alternateDefault: "/export/es/acerca-de/"
}

const QuienesSomos = ({ location }) => (


  <Layout location={location} crumbLabel="Quienes Somos"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>

    <StaticImage src="../images/equipo-unilimpio.jpg"
                className="object-contain"
                alt="Acerca de Unilimpio: en la imagen todos los colaboradores de Unilimpio oficina Matriz."
                placeholder="blurred"
                layout="fullWidth"
                 />

    <section id="presentacion" className="bg-white flex justify-center">
  <div className="max-w-7xl mx-10 py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
    <div className="max-w-3xl mx-auto text-center">
    <h1 className="text-5xl font-semibold text-primary-lighter ">Somos Salud y Bienestar</h1>
           <p className="mt-4 text-2xl text-primary-default mb-6 leading-relaxed ">Somos una empresa orgullosamente Ecuatoriana, que desde hace más de 3 décadas brindamos salud y bienestar a las personas a través de limpieza e higiene.</p>
    </div>
    </div>
</section>



        <SplitSection id="video"
      reverseOrder className="bg-gray-300 px-2   "
      primarySlot={
        <div className="bg-white bg-opacity-50 rounded-lg  my-20  px-6 py-16 mx-6">

          <p className="mt-1.5 text-xl font-normal leading-relaxed text-center ">
          Reportaje realizado por Mucho Mejor Ecuador sobre el proceso de fabricación de nuestros productos de limpieza.
          </p>

        </div>
      }
      secondarySlot={
        <div className="video-responsive flex justify-center " >
        <iframe width="560" height="315"
            src="https://www.youtube.com/embed/B412e9m5H2g"
            title="YouTube video player "
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
        </iframe>
    </div>

}
/>



<section id="historia" className="bg-blue-900  flex justify-center">
  <div className="max-w-7xl mx-10 py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
    <div className="max-w-3xl mx-auto text-center">
    <h2 className="text-4xl font-semibold text-white text-center pb-4 ">NUESTRA HISTORIA</h2>
      <p className="mt-4 text-2xl text-slate-100 mb-20 font-normal text-left">Unilimpio S.A es una empresa Ecuatoriana que nació en 1986. Nos dedicamos a proveer sistemas de limpieza para la industria, las instituciones y el hogar. Nuestro propósito es proveer bienestar a las personas a través de soluciones de higiene.
          <br/> <br/>
Tras 34 años, nos hemos convertido en un grupo empresarial constituido por empresas especializadas en diferentes campos de la higiene, con exportaciones a centro y sudamérica.
<br/> <br/>
Cumplimos con certificaciones nacionales e internacionales que avalan la calidad de nuestros productos.
 </p>

<h1 className=" italic font-semibold text-center text-white text-2xl ">"La higiene es salud y bienestar" </h1>
    </div>
    </div>
</section>

<section id="grupo empresarial Unilimpio" className="">
<div className="bg-primary-lighter">
  <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div>
        <h2 className="text-4xl font-semibold text-white sm:text-4xl text-center">Grupo Empresarial Unilimpio</h2>
        <p className="mt-3 max-w-3xl text-2xl text-white">Tras 34 años en el mercado, nos hemos convertido en un Grupo Empresarial constituido por empresas especializadas en diferentes campos de la higiene:</p>
      </div>
      <div className="mt-8 grid grid-cols-2 gap-2 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
        <div className="col-span-1 flex justify-center py-8 px-8 bg-white rounded">
        <StaticImage src="../images/unilimpio.jpg"
                className="mx-auto"
                alt="Acerca de Unilimpio:imagen del logo de la empresa Unilimpio SA"
                placeholder="blurred"
                layout="constrained"
                 />
        </div>
        <div className="col-span-1 flex justify-center py-8 px-8 bg-white rounded">
        <StaticImage src="../images/tecno-clean.jpg"
                className="mx-auto"
                alt="Acerca de Unilimpio:imagen del logo de la empresa Unilimpio SA"
                placeholder="blurred"
                layout="constrained"
                 />
        </div>
      </div>
    </div>
  </div>
</div>
</section>







<section className="bg-lime-500 pt-12 sm:pt-16 mb-6">
  <div className="max-w-7xl  mx-auto px-4 sm:px-6 lg:px-8 ">
    <div className="max-w-4xl mx-auto text-center ">
      <h2 className="text-4xl font-semibold mt-0 text-center text-white mb-12  ">
        MEJORA CONSTANTE
      </h2>
    </div>
  </div>
  <div className="bg-lime-500 py-px pb-12 sm:pb-16">
    <div className="relative ">
      <div className="absolute inset-0 h-1/2 bg-lime-500"></div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-4  ">
            <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-blue-900">
              Certificación ISO 9001
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter ">
                2001
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-blue-900">
              Miembro de la Internacional Sanitary Supply Association ISSA
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2009
              </dd>
            </div>
            <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-blue-900">
              Adopción de Estándares ISO 4500 e ISO 14000
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2012
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-blue-900">
              Responsabilidad Social con el marco de conducta de ISO 26000
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2016
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-blue-900">
              Miembro del Pacto Global de Naciones Unidas
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2018
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-blue-900">
              Certificación en Buenas Prácticas de Manufactura
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2021
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-blue-900">
              Miembro de la PECC (Programa Ecuador Carbono Cero)
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2021
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-lg leading-6 font-normal text-blue-900">
              Certificación FSC®
              </dt>
              <dd className="order-1 text-4xl font-semibold text-secondary-lighter">
                2023
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="grupo empresarial Unilimpio" className="bg-white flex justify-center mt-2">
  <div className="max-w-7xl mx-10 py-2 px-4 sm:px-6 lg:py-24 lg:px-8">
    <div className="max-w-3xl mx-auto text-center flex justify-center">
      <h1 className="text-4xl font-semibold mt-0 text-center text-primary-lighter  ">NUESTRA FILOSOFÍA</h1>
    </div>
    </div>
</section>


<section className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3  mx-6 mt-0">
  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div className="flex-1 flex flex-col p-8">
    <StaticImage src="../images/nuestro-proposito.jpg"
                    className="flex justify-center mx-6"
                    alt="Misión de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"
        />
      <h3 className="mt-6 text-secondary  text-3xl font-bold ">Nuestra Misión</h3><br/>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Title</dt>
        <dd className="text-blue-900 text-xl text-left">El bienestar de las personas a través de la higiene.</dd>
        <dt className="sr-only">Role</dt>
        <dd className="mt-3">

        </dd>
      </dl>
    </div>
  </li>

  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div className="flex-1 flex flex-col p-8">
    <StaticImage src="../images/nuestro-futuro.jpg"
                    className="flex justify-center mx-6"
                    alt="Visión de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"
        />
      <h3 className="mt-6 text-secondary  text-3xl font-bold">Nuestro Visión</h3><br/>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Title</dt>
        <dd className="text-blue-900 text-xl text-left">Ser la primera opción de higiene institucional de productos de limpieza, seremos un gran lugar para trabajar, nos reconocerán por ser una empresa sustentable y de gran reputación en el país, exportaremos a 5 países de Latinoamérica.</dd>
        <dt className="sr-only">Role</dt>
        <dd className="mt-3">

        </dd>
      </dl>
    </div>

  </li>

  <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div className="flex-1 flex flex-col p-8">
    <StaticImage src="../images/valores-corporativos.jpg"
                    className="flex justify-center mx-6"
                    alt="Valores corporativos de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"
        />
      <h3 className="mt-6 text-secondary  text-3xl font-bold">Nuestros Valores Corporativos</h3><br/>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dt className="sr-only">Title</dt>
        <dd className="text-blue-900 text-xl text-left"><li>Enfoque en el cliente</li><li>Mejora permanente e innovación</li> <li>trabajo en equipo</li> <li>Transparencia, honestidad, coraje y fe</li> <li>Sustentables (Pacto Global y ODS)</li> </dd>
        <dt className="sr-only">Role</dt>
        <dd className="mt-3">
        </dd>
      </dl>
    </div>
  </li>
</section>



<section className="  bg-gray-300 mt-10">
  <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
    <div className="space-y-12">
      <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
        <h2 className="text-4xl font-semibold mt-10 text-center text-primary-lighter">NUESTRAS MARCAS</h2>

      </div>
      <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-4 lg:max-w-5xl">
        <li>
          <div className="space-y-6 object-center">
          <div> <StaticImage src="../images/biosolutions.jpg"
                    className="rounded"
                    alt="marca ecologica biosolutions"
                    placeholder="blurred"
                    layout="constrained"
        /></div>

            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1 font-bold">
                <h3>BIOSOLUTIONS</h3>
                <dd className="text-blue-900 text-base font-normal px-4 text-left">Marca de productos ecológicos para el cuidado del hogar e institucional, desarrollados para minimizar el impacto ambiental. </dd>
              </div>

            </div>
          </div>
        </li>

        <li>
          <div className="space-y-6">
          <div> <StaticImage src="../images/unilimpio.jpg"
                    className="rounded"
                    alt="marca de quimicos unilimpio"
                    placeholder="blurred"
                    layout="constrained"
        /></div>

            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1 font-bold">
                <h3>UNILIMPIO</h3>
                <dd className="text-blue-900 text-base font-normal px-4 text-left">Empresa ecuatoriana especializada en el desarrollo y fabricación de productos innovadores para la limpieza, mantenimiento y cuidado personal.  </dd>

              </div>

            </div>
          </div>
        </li>

        <li>
          <div className="space-y-6">
          <div> <StaticImage src="../images/sanitisu.jpg"
                    className="rounded"
                    alt="marca de absorbentes sanitisu"
                    placeholder="blurred"
                    layout="constrained"
        /></div>
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1 font-bold">
                <h3>SANITISÚ</h3>
                <dd className="text-blue-900 text-base font-normal px-4 text-left">Marca de absorbentes ecológicos para la limpieza de manos.  </dd>
              </div>

            </div>
          </div>
        </li>
        <li>
          <div className="space-y-6">
          <div> <StaticImage src="../images/dr-clean.jpg"
                    className="rounded"
                    alt="marca de jabon, gel, alcohol de manos dr.clean"
                    placeholder="blurred"
                    layout="constrained"
        /></div>
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1 font-bold">
                <h3>DR.CLEAN</h3>
                <dd className="text-blue-900 text-base font-normal px-4 text-left">Marca de productos de higiene y desinfección de manos. </dd>
              </div>

            </div>
          </div>
        </li>
        <li>
          <div className="space-y-6">
          <div> <StaticImage src="../images/ozz.jpg"
                    className="rounded"
                    alt="marca de quimicos, desinfectantes ozz"
                    placeholder="blurred"
                    layout="constrained"
        /></div>
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1 font-bold">
                <h3>OZZ</h3>
                <dd className="text-blue-900 text-base font-normal px-4 text-left">Marca de productos innovadores para la limpieza, mantenimiento y cuidado personal. </dd>
              </div>

            </div>
          </div>
        </li>

        <li>
          <div className="space-y-6">
          <div> <StaticImage src="../images/tts-cleaning.jpg"
                    className="rounded"
                    alt="marca de accesorios profesionales para limpieza tts cleaning"
                    placeholder="blurred"
                    layout="constrained"
        /></div>
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1 font-bold">
                <h3>TTS Cleaning</h3>
                <dd className="text-blue-900 text-base font-normal px-4 text-left">TTS Cleaning es una empresa italiana, con el tiempo se ha convertido en el punto de referencia de la limpieza profesional.</dd>
              </div>

            </div>
          </div>
        </li>

        <li>
          <div className="space-y-6">
          <div> <StaticImage src="../images/bobson.jpg"
                    className="rounded"
                    alt="marca de dispensadores bobson"
                    placeholder="blurred"
                    layout="constrained"
        /></div>
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1 font-bold">
                <h3>BOBSON</h3>
                <dd className="text-blue-900 text-base font-normal px-4 text-left">Marca de dispensadores de jabón, gel, papel higiénico, toallas de mano.  </dd>
              </div>

            </div>
          </div>
        </li>

        <li>
          <div className="space-y-6">
          <div> <StaticImage src="../images/tecno-clean.jpg"
                    className="rounded"
                    alt="soluciones integrales personalizadas para su lavandería; contribuyendo a la limpieza, higiene, cuidado, conservación y preservación textil de sus prendas."
                    placeholder="blurred"
                    layout="constrained"
        /></div>
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1 font-bold">
                <h3>TECNO CLEAN</h3>
                <dd className="text-blue-900 text-base font-normal px-4 text-left">Empresa dedicada a la fabricación y comercialización de productos de limpieza e higiene textil. </dd>
              </div>

            </div>
          </div>
        </li>


      </ul>
    </div>
  </div>
</section>




<NewsletterSub/>

</Layout>
);

export default QuienesSomos;
